import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';

export default function Clienti({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const columns=[
		{ field: 'id', sortable:false, headerName: 'ID', width: 90, type:'number' },
		{ field: 'code', sortable:false, headerName: 'Codice', width: 80 },
		{ field: 'name', sortable:false, headerName: 'Denominazione', width: 450, flex: true },
		{ field: 'vat_number', sortable:false, headerName: 'Partita IVA', width: 150 },
		{ field: 'tax_code', sortable:false, headerName: 'Codice Fiscale', width: 180 },
		// { field: 'address_city', sortable:false, headerName: 'Città', width: 165 },
		// { field: 'address_province', sortable:false, headerName: 'Provincia', width: 80 },
		{ field: 'country', sortable:false, headerName: 'Paese', width: 80 },
		{ field: 'denominazioneAgente', sortable:false, headerName: 'Agente', width: 240 },
	]

	const caricaRows = () => {
		api.get("/clienti/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Clienti');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/clienti/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/clienti/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			/* <ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/clienti/new") }>Aggiungi</ContextCommand>, */
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/clienti/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutti i clienti che sono presenti su Fatture in Cloud.
			</p>
			<DataGrid
				localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				rowHeight={25}
				columns={columns}
				disableColumnMenu
				disableColumnFilter
        		disableDensitySelector
				initialState={{
					filter: {
						filterModel: {
						items: [],
						quickFilterValues: [''],
						},
					},
					pagination: { paginationModel: { pageSize: 20 } },
				}}
				pageSizeOptions={[5, 10, 20]}
				slots={{
					toolbar: GridToolbar,
				}}
				slotProps={{
					toolbar: {
					  showQuickFilter: true,
					},
				}}
				checkboxSelection={false}
				onRowSelectionModelChange={handleSelectionChange}
				disableRowSelectionOnClick
				onRowClick={rowDettaglio}
				autoHeight
			/>
		</Box>
		);
}
