import React, { useEffect, useState } from 'react';
import api from '../lib/api';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import FormTitle from '../components/FormTitle';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';

export default function Statistiche({setTitle, setContextCommands}) {
	const [ rowsMesi, setRowsMesi ] = useState([]);
	const [ rowsMesiCosti, setRowsMesiCosti ] = useState([]);
	const [ rowsComulativo, setRowsComulativo ] = useState([]);
	const [ rowsComulativoCosti, setRowsComulativoCosti, ] = useState([]);
	const [ rowsClienti, setRowsClienti ] = useState([]);
	const [ rowsFornitori, setRowsFornitori ] = useState([]);
	const [ rowsAgenti, setRowsAgenti ] = useState([]);

	const columnsMesi=[
		{ field: 'anno', sortable:false, headerName: 'Anno', width: 60, renderCell: (params) => (<strong>{params.value}</strong>) },
		{ field: '1', sortable:false, headerName: 'Gennaio', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '2', sortable:false, headerName: 'Febbraio', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '3', sortable:false, headerName: 'Marzo', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '4', sortable:false, headerName: 'Aprile', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '5', sortable:false, headerName: 'Maggio', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '6', sortable:false, headerName: 'Giugno', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '7', sortable:false, headerName: 'Luglio', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '8', sortable:false, headerName: 'Agosto', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '9', sortable:false, headerName: 'Settembre', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '10', sortable:false, headerName: 'Ottobre', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '11', sortable:false, headerName: 'Novembre', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: '12', sortable:false, headerName: 'Dicembre', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },
		{ field: 'totale', sortable:false, headerName: 'TOTALE', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => { return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value); } },	
	]

	const currentYear = new Date().getFullYear();
	const startYear = 2012;

	const columnsClienti = [
		{ field: 'denominazione', sortable: false, headerName: 'Cliente', cellClassName: 'sticky-col', minWidth: 480, flex: true, renderCell: (params) => (<strong>{params.value}</strong>) },
		...Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
			const year = startYear + i;
			return {
				field: year.toString(),
				sortable: false,
				headerName: year.toString(),
				width: 100,
				headerAlign: 'right',
				align: 'right',
				valueFormatter: params => {
					return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);
				}
			};
		}),
		{ field: 'totale', sortable: false, headerName: 'TOTALE', width: 120, headerAlign: 'right', align: 'right', valueFormatter: params => {return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);} }
	];

	const columnsAgenti = [
		{ field: 'denominazioneAgente', sortable: false, headerName: 'Agente', cellClassName: 'sticky-col', minWidth: 480, flex: true, renderCell: (params) => (<strong>{params.value}</strong>) },
		{ field: 'provvigione', sortable: false, headerName: 'Provvigione', width: 100, type:'number', valueFormatter: (params) => `${params.value} %`, },
		...Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
			const year = startYear + i;
			return {
				field: year.toString(),
				sortable: false,
				headerName: year.toString(),
				width: 100,
				headerAlign: 'right',
				align: 'right',
				valueGetter: (params) => {
					const fatturatoAnnuo = params.row[year] || 0;
					const fatturatoProvvigione = params.row[`${year}_P`] || 0;
					return { fatturatoAnnuo, fatturatoProvvigione };
				},
				renderCell: (params) => {
					const { fatturatoAnnuo, fatturatoProvvigione } = params.value || {};
					return (
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
							<span>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(fatturatoAnnuo)}</span>
							<small>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(fatturatoProvvigione)}</small>
						</div>
					);
				}
			};
		}),
		{ field: 'totale', sortable: false, headerName: 'TOTALE', width: 120, headerAlign: 'right', align: 'right', valueFormatter: params => {
			return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);
		} }
	];
	
	const caricaRows = () => {
		api.get("/statistiche/fatturato/mese").then(data=>{
		  setRowsMesi(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
		api.get("/statistiche/costi/mese").then(data=>{
			setRowsMesiCosti(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
		api.get("/statistiche/fatturato/cumulativo").then(data=>{
			setRowsComulativo(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
		api.get("/statistiche/costi/cumulativo").then(data=>{
			setRowsComulativoCosti(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
		api.get("/statistiche/fatturato/cliente").then(data=>{
			setRowsClienti(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
		api.get("/statistiche/costi/fornitore").then(data=>{
			setRowsFornitori(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
		api.get("/statistiche/fatturato/agenti").then(data=>{
			setRowsAgenti(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Statistiche');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	return (
		<Box sx={{ height: '100%', width: '100%' }} noValidate autoComplete="off">
		  <Grid container spacing={2}>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<FormTitle variant="h6">Fatturato per mese</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
						<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsMesi}
							rowHeight={25}
							columns={columnsMesi}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							getRowId={(r) => r.anno}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
							autoHeight
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
			  			<FormTitle variant="h6">Fatturato per mese cumulativo</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
						<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsComulativo}
							rowHeight={25}
							columns={columnsMesi}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							getRowId={(r) => r.anno}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
							autoHeight
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<FormTitle variant="h6">Fatturato per cliente</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
						<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsClienti}
							rowHeight={25}
							columns={columnsClienti}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ filter: {
								filterModel: {
								items: [],
								quickFilterValues: [''],
								},
							}, pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							slotProps={{
								toolbar: {
								showQuickFilter: true,
								},
							}}
							getRowId={(r) => r.id}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
							autoHeight
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<FormTitle variant="h6">Fatturato portato degli agenti</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
						<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsAgenti}
							rowHeight={45}
							columns={columnsAgenti}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ filter: {
								filterModel: {
								items: [],
								quickFilterValues: [''],
								},
							}, pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							slotProps={{
								toolbar: {
								showQuickFilter: true,
								},
							}}
							getRowId={(r) => r.agente}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<FormTitle variant="h6">Costi per mese</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
						<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsMesiCosti}
							rowHeight={45}
							columns={columnsMesi}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ filter: {
								filterModel: {
								items: [],
								quickFilterValues: [''],
								},
							}, pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							slotProps={{
								toolbar: {
								showQuickFilter: true,
								},
							}}
							getRowId={(r) => r.anno}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<FormTitle variant="h6">Costi per mese cumulativo</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
						<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsComulativoCosti}
							rowHeight={25}
							columns={columnsMesi}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							getRowId={(r) => r.anno}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
							autoHeight
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<Accordion>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<FormTitle variant="h6">Costi per fornitore</FormTitle>
					</AccordionSummary>
					<AccordionDetails>
					<DataGrid
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={rowsFornitori}
							rowHeight={25}
							columns={columnsClienti}
							disableColumnMenu
							disableColumnFilter
							disableDensitySelector
							initialState={{ filter: {
								filterModel: {
								items: [],
								quickFilterValues: [''],
								},
							}, pagination: { paginationModel: { pageSize: 5 } } }}
							slots={{ toolbar: GridToolbar }}
							slotProps={{
								toolbar: {
								showQuickFilter: true,
								},
							}}
							getRowId={(r) => r.id}
							pageSizeOptions={[5, 10, 15, 20]}
							checkboxSelection={false}
							sx={{
								"& .MuiDataGrid-row.Mui-selected": {
									backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore di sfondo personalizzato
									"&:hover": {
										backgroundColor: "rgba(20, 110, 200, 0.15)", // Colore al passaggio del mouse
									},
								},
							}}
							autoHeight
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
		  </Grid>
		</Box>
	  );
	}