import React, { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import { DateTime } from "luxon";

export default function Costi({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	// const navigate = useNavigate();


	const columns=[
		{ field: 'id', sortable:false, headerName: 'ID', width: 100 },
		{ field: 'denominazione', sortable:false, headerName: 'Cliente', width: 500},
		{ field: 'numero_fattura', sortable:false, headerName: 'Numero fattura', width: 120},
		{ field: 'data_fattura', sortable:false, headerName: 'Data fattura', width: 120, valueGetter: (params) => { return DateTime.fromSQL(params.row.data_fattura).toFormat('dd/MM/yyyy'); } },
		{ field: 'totale_netto', sortable:false, headerName: 'Imponibile', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => {return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);} },
		{ field: 'totale_iva', sortable:false, headerName: 'IVA', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => {return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);}  },
		{ field: 'totale_ritenuta', sortable:false, headerName: 'Ritenuta', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => {return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);}  },
		{ field: 'totale_altra_ritenuta', sortable:false, headerName: 'Altra ritenuta', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => {return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);}  },
		
		{ field: 'totale_lordo', sortable:false, headerName: 'TOTALE', width: 100, headerAlign: 'right', align: 'right', valueFormatter: params => {return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(params.value);}  },
	]

	const caricaRows = () => {
		api.get("/costi/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Costi');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	/*
	const rowDettaglio = (params) => {
		navigate(encodeURI("/costi/"+params.id));
	};
	*/

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutti i costi presenti su Fatture in Cloud.
			</p>
			<DataGrid
				localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				rowHeight={25}
				columns={columns}
				disableColumnMenu
				disableColumnFilter
        		disableDensitySelector
				initialState={{
					filter: {
						filterModel: {
						items: [],
						quickFilterValues: [''],
						},
					},
					pagination: { paginationModel: { pageSize: 20 } },
				}}
				pageSizeOptions={[5, 10, 20]}
				slots={{
					toolbar: GridToolbar,
				}}
				slotProps={{
					toolbar: {
					  showQuickFilter: true,
					},
				}}
				checkboxSelection={false}
				disableRowSelectionOnClick
				// onRowClick={rowDettaglio}
				autoHeight
			/>
		</Box>
		);
}
