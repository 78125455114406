const username = localStorage.getItem('username');

var menu = [];

menu.push({ id: 0, text: "Home", icon: "home", url: "/", home: true });
menu.push({ id: 1, text: "-" });
menu.push({ id: 2, text: "Clienti", icon: "account-multiple", url: "/clienti", home: false });
menu.push({ id: 3, text: "Agenti", icon: "account-multiple", url: "/agenti", home: false });
// menu.push({ id: 4, text: "Agenti - Clienti", icon: "account-multiple", url: "/clienti", home: false });
menu.push({ id: 5, text: "-" });
menu.push({ id: 6, text: "Contratti", icon: "file-document-edit", url: "/contratti", home: false });
menu.push({ id: 7, text: "Servizi", icon: "account-wrench", subMenu: [
  { id: 100, text: "Aruba Business", icon: "account-wrench", url: "/servizi" },
  { id: 101, text: "Mediatech", icon: "account-wrench", url: "/mediatech" },
]});
menu.push({ id: 8, text: "Scadenze", icon: "account-wrench", url: "/scadenze", home: false });
menu.push({ id: 10, text: "-" });
menu.push({ id: 11, text: "Listino", icon: "list-box", url: "/listino", home: false });
menu.push({ id: 12, text: "Fatture", icon: "file-certificate", url: "/fatture", home: false });
menu.push({ id: 13, text: "Costi", icon: "file-certificate", url: "/costi", home: false });
menu.push({ id: 14, text: "Statistiche", icon: "chart-areaspline", url: "/statistiche", home: false });
if (username === "stefano.lanfranco") {
  menu.push({ id: 20, text: "-" });
  menu.push({ id: 21, text: "Sync", icon: "cogs", subMenu: [
    { id: 200, text: "Fatture in Cloud", icon: "receipt-text", url: "/sync/fattureincloud" },
    { id: 201, text: "Aruba Business", icon: "web-sync", url: "/sync/arubabusiness" },
    { id: 202, text: "Pianeta Fibra", icon: "ip", url: "/sync/pianetafibra" },
  ]});
}
menu.push({ id: 40, text: "-" });
menu.push({ id: 41, text: "Logout", icon: "logout", url: "/logout", home: false });

export default menu;