import React, { useEffect, useState, /* useCallback */ } from 'react';
// import { useNavigate } from "react-router-dom";
// import ContextCommand from '../components/ContextCommand';
// import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';

export default function Agenti({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	// const [ selectedIDs, setSelectedIDs ] = useState([]);
	// const navigate = useNavigate();
	// const { pushNotification } = useNotification();

	const columns=[
		{ field: 'id', sortable:false, headerName: 'ID', width: 90, type:'number' },
		{ field: 'denominazione', sortable:false, headerName: 'Denominazione', width: 450, flex: true },
		{ field: 'provvigione', sortable: false, headerName: 'Provvigione', width: 100, type:'number', valueFormatter: (params) => `${params.value} %`, },
	]

	const caricaRows = () => {
		api.get("/agenti/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Agenti');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	/*
	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/agenti/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/agenti/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);
	*/

	/*
	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/agenti/new") }>Aggiungi</ContextCommand>,
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );
	*/

	/*
	const rowDettaglio = (params) => {
		navigate(encodeURI("/agenti/"+params.id));
	};
	*/

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutti gli agenti.
			</p>
			<DataGrid
				localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				rowHeight={25}
				columns={columns}
				disableColumnMenu
				disableColumnFilter
        		disableDensitySelector
				initialState={{
					filter: {
						filterModel: {
						items: [],
						quickFilterValues: [''],
						},
					},
					pagination: { paginationModel: { pageSize: 20 } },
				}}
				pageSizeOptions={[5, 10, 20]}
				slots={{
					toolbar: GridToolbar,
				}}
				slotProps={{
					toolbar: {
					  showQuickFilter: true,
					},
				}}
				disableCheckboxSelection
				disableRowSelectionOnClick
				// onRowClick={rowDettaglio}
				autoHeight
			/>
		</Box>
		);
}
