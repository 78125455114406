import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

export default function Home({ setTitle, setContextCommands }) {

  useEffect(() => {
    setTitle('Home');
    setContextCommands([]);
  }, [setTitle, setContextCommands]);

  return (
    <Box sx={{ height: '100%', width: '100%', padding: 2 }}>
      <h1>Lanfranco Stefano</h1>
      <p>
        Benvenuto nel software di gestione clienti. Questo strumento ti permette di:
      </p>
      <ul>
        <li>Visualizzare e gestire tutti i clienti.</li>
        <li>Monitorare i servizi offerti e le relative scadenze.</li>
        <li>Sincronizzare i dati con Fatture in Cloud, Aruba Business e presto con Pianeta Fibra.</li>
      </ul>
      <p>
        Utilizza il menu di navigazione per accedere alle diverse funzionalità del sistema.
      </p>
    </Box>
  );
}