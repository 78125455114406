import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { itIT } from '@mui/x-data-grid/locales';
import { DateTime } from "luxon";

export default function Contratti({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const columns=[
		{ field: 'id', headerName: 'ID', width: 120 },
		{ field: 'name', headerName: 'Cliente', width: 340 },
		{ field: 'descrizione', headerName: 'Descrizione', width: 360/*, flex:true*/ },
		{ field: 'data_inizio', headerName: 'Data inzio', width: 180, valueGetter: (params) => { return DateTime.fromSQL(params.row.data_inizio).toFormat('dd/MM/yyyy'); } },
		{ field: 'data_fine', headerName: 'Data fine', width: 180, valueGetter: (params) => { 
			const dataFine = DateTime.fromSQL(params.row.data_fine);
			return dataFine.isValid ? dataFine.toFormat('dd/MM/yyyy') : ""; 
		  } },
	]

	const caricaRows = () => {
		api.get("/contratti/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
	}

	useEffect(() => {
	    setTitle('Contratti');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/contratti/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/contratti/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/contratti/new") }>Aggiungi</ContextCommand>,
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/contratti/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutti i contratti fatturati dei servizi attivi di Aruba Business.
			</p>
			<DataGrid
				localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				rowHeight={25}
				columns={columns}
				disableColumnMenu
				disableColumnFilter
        		disableDensitySelector
				initialState={{
					filter: {
						filterModel: {
						items: [],
						quickFilterValues: [''],
						},
					},
					pagination: { paginationModel: { pageSize: 20 } },
				}}
				pageSizeOptions={[5, 10, 20]}
				slots={{
					toolbar: GridToolbar,
				}}
				slotProps={{
					toolbar: {
					  showQuickFilter: true,
					},
				}}
				checkboxSelection
				onRowSelectionModelChange={handleSelectionChange}
				disableRowSelectionOnClick
				onRowClick={rowDettaglio}
				autoHeight
			/>
		</Box>
		);
}
