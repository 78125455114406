import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Loader from './../components/Loader';
import useNotification from './../components/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormCheckbox from '../components/FormCheckbox';
import FormTextField from '../components/FormTextField';

export default function ListinoDettaglio({setTitle, setContextCommands}) {
	const { id } = useParams();
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();

	const caricaProdotto = useCallback(() => {
		if (id!=='new') {
			api.get(`/prodotti/${id}`).then(data=>{
				const prodotto = data[0];
				prodotto._action="e";
				reset(prodotto);
			}, error=>{
				console.log("Impossibile caricare i dati", "error");
			});
		}
	}, [id, reset]);

	useEffect(() => {
		setLoaderOpen(true);
	    setTitle('Dettaglio del prodotto');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaProdotto();
  	}, [setTitle, setContextCommands, caricaProdotto]);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			setLoaderOpen(false);
		} else {
			api.get(`/prodotti/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const prodotto = data[0];
					prodotto._action="e";
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue]);

	const salva = useCallback(data=>{
		var array = [];
		array.push(data);
		const json = nullZLSTabella(array);
		api.post("/prodotti", json).then(r=>{
			pushNotification("Dati salvati", "success");
			navigate("/listino/"+r);
		}, error=>{
			pushNotification("Errore nel salvataggio del prodotto", "error");
		});
	}, [pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/listino") }>Indietro</ContextCommand>,
			/*<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>*/
		]);
	}, [setContextCommands, handleSubmit, navigate, salva] );

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={2}>
				<FormTextField xs={6} md={6} size="small" control={control} name="id" label="ID" type="text" disabled/>
				<FormTextField xs={6} md={6} size="small" placeholder="Codice" control={control} name="code" label="Codice" type="text" disabled/>

				<FormTextField xs={12} md={12} size="small" placeholder="Titolo prodotto" control={control} name="name" label="Titolo prodotto" type="text" disabled/>

				<FormTextField xs={12} md={12} size="small" placeholder="Descrizione" control={control} name="description" label="Descrizione" type="text" controlProps={{ multiline:true, rows:6 }} disabled/>

				<FormTextField xs={6} md={6} size="small" placeholder="Prezzo netto" control={control} name="net_price" label="Prezzo netto" type="text" disabled/>
				<FormTextField xs={6} md={6} size="small" placeholder="UM" control={control} name="measure" label="UM" type="text" disabled/>

				<FormTextField xs={6} md={6} size="small" placeholder="Prezzo lordo" control={control} name="gross_price" label="Prezzo lordo" type="text" disabled/>
				<FormCheckbox xs={6} md={6} control={control} name="use_goss_price" label="Usa presso lordo" disabled/>
				
				<FormTextField xs={6} md={6} size="small" placeholder="net_cost" control={control} name="net_cost" label="net_cost" type="text" disabled/>
				<FormTextField xs={6} md={6} size="small" placeholder="category" control={control} name="category" label="category" type="text" disabled/>

				<FormCheckbox xs={6} md={6} control={control} name="in_stock" label="Magazzino abilitato" disabled/>
				<FormTextField xs={6} md={6} size="small" placeholder="IVA di default" control={control} name="default_vat" label="IVA di default" type="text" disabled/>
				
			</Grid>
		</Box>
	);
}
